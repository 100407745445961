import { Link, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import clsx from "clsx";
import { useState, useEffect } from "react";

import { navRoutes, routesFlat } from "@routes";
import { NavItem } from "./components";
import { Logout } from "../../Login/components";
import { BlackLogo } from "@assets/icons";

function getBgColor(currentRoute) {
    if (currentRoute.includes(routesFlat.news)) {
        return " bg-green";
    }
    return " bg-background";
}

export default function NavDesktop() {
    const { isAuthenticated } = useAuth0();
    const currentRoute = useLocation();
    const bgColor = getBgColor(currentRoute.pathname);

    const [scrollOpacity, setScrollOpacity] = useState(0.2);

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            const opacity = Math.min(0.2 + scrollY / 800, 1);
            setScrollOpacity(opacity);
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const parentStyle = clsx(parentWrapperStyle, bgColor);

    return (
        <div
            className={parentStyle}
            style={{
                backgroundColor: `rgba(255, 255, 255, ${scrollOpacity})`,
            }}
        >
            <Link to={routesFlat.landing}>
                <BlackLogo withText={true} className={logoStyle} />
            </Link>
            <div className={routesContainerStyle}>
                {navRoutes.map((route) => (
                    <NavItem route={route} />
                ))}
                {isAuthenticated && <Logout />}
            </div>
        </div>
    );
}

const parentWrapperStyle = clsx(
    "fixed top-0 z-50 hidden lg:flex w-full justify-between transition-all duration-300",
);
const logoStyle = clsx("h-[40px] w-auto self-center md:h-[60px]");
const routesContainerStyle = clsx(
    "flex justify-around w-1/2 pr-6 items-center",
);
