import { ChartType, ZoomControls } from "@/assets/beats/products/overlays";
import { ImageComparisonSlider } from "@/components";

export const hero = {
    bgColor: "green",
    headerImg: "https://ctrees-website.s3.amazonaws.com/webv3/products/hero/land-carbon.png",
    title: "Land Carbon Map",
    subheader: "Measure carbon in all forest and non-forest land globally",
    desc: "CTrees’ land carbon maps provide jurisdictions and organizations implementing major climate programs with accurate estimates of carbon at 1-hectare resolution in all forests and non-forest areas, based on CTrees’ global data platform. ",
};

export const features = [
    {
        title: "Historical accuracy for inventories and baselines",
        img: <ImageComparisonSlider imgBefore={"https://ctrees-website.s3.amazonaws.com/webv3/products/beats/treelevelbefore.png"} 
                imgBeforeAlt={'Tree level before'} 
                imgAfter={"https://ctrees-website.s3.amazonaws.com/webv3/products/beats/treelevelafter.png"} 
                imgAfterAlt={'Tree level after'} />,
        list: [
            "Access accurate geospatial data at 1-hectare resolution from 2000 to present.",
            "Meet IPCC Tier 3 standards for wall-to-wall mapping, for national greenhouse gas inventories and Paris Agreement nationally determined contributions.",
        ],
    },
    {
        title: "Data for policy and carbon markets",
        bgImg: "https://ctrees-website.s3.amazonaws.com/webv3/products/beats/land-carbon-beat-2.png",
        img: <ChartType />,
        list: [
            "Meet accuracy requirements for calculating emission factors at project or jurisdictional levels. ",
            "Establish accurate forest reference emission levels at jurisdiction scales for REDD finance. ",
            "Understand spatial confidence level for prediction of carbon risks and returns.",
        ],
    },
    {
        title: "Comprehensive coverage",
        bgImg: "https://ctrees-website.s3.amazonaws.com/webv3/products/beats/land-carbon-beat-3.png",
        img: <ZoomControls />,
        list: [
            "Estimate carbon for all ecosystems on land: forests, woodlands, grasslands, urban areas, croplands, coastal wetlands, and mangroves.",
            "Understand impacts of climate change, soil, and disturbance processes.",
        ],
    },
];

export const questionList = [
    {
        question:
            "Understand impacts of climate change, soil, and disturbance processes?",
        answer: "Estimate carbon for all ecosystems on land: forests, woodlands, grasslands, urban areas, croplands, coastal wetlands, and mangroves.",
    },
    {
        question:
            "Access accurate geospatial data at 1-hectare resolution from 2000 to present?",
        answer: "Estimate carbon for all ecosystems on land: forests, woodlands, grasslands, urban areas, croplands, coastal wetlands, and mangroves.",
    },
    {
        question: "Measure carbon in all forest and non-forest land globally?",
        answer: "Estimate carbon for all ecosystems on land: forests, woodlands, grasslands, urban areas, croplands, coastal wetlands, and mangroves.",
    },
];
