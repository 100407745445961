import clsx from "clsx";
import { motion } from "framer-motion";
import { DottedDivider } from "@components";

export default function VerticalTabs(props) {
    return (
        <div className={tabContainerStyle}>
            {props.tabs.map((tab, i) => {
                const selected = props.selectedTab === i;
                return (
                    <>
                        <div className="flex items-center px-4 py-2">
                            <div className="w-4">
                                {selected && (
                                    <motion.div
                                        layoutId="dot"
                                        className={dotStyle}
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                    />
                                )}
                            </div>
                            <button
                                key={tab}
                                onClick={() => props.setSelectedTab(i)}
                                className={
                                    tabStyle +
                                    (selected ? "text-black" : "text-black/50")
                                }
                            >
                                {tab}
                            </button>
                        </div>
                        <DottedDivider className="w-full last:hidden" />
                    </>
                );
            })}
        </div>
    );
}

const tabStyle = clsx(
    "flex w-[300px] items-center space-x-2 px-4 py-2 text-left text-2xl uppercase tracking-wider focus:outline-none ",
);
const tabContainerStyle = clsx("relative flex w-1/3 flex-col items-start p-4");
const dotStyle = clsx("h-4 w-4 rounded-full bg-green");
