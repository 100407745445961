import { Link, useParams } from "react-router-dom";
import clsx from "clsx";
import { formatDateMonth } from "@helpers";
import { feTagsFlat } from "@helpers/constants";
import * as styles from "./styles";
import { tag as tagStyle } from "../Blue/styles";
import ScaleOnHoverDiv from "../../ScaleOnHoverDiv";

export default function GreenCard({
    date,
    img,
    imgAlt,
    title,
    subheader,
    linkDisplayText,
    link,
    children,
    tags,
}) {
    // If pre-existing id, need to ensure there's no
    // double nesting by using <a/> instead of <Link/>
    const { id } = useParams();

    const CardBody = () => (
        <>
            <div className={styles.imgContainer}>
                <img
                    src={img}
                    alt={imgAlt}
                    className="min-h-full min-w-full object-cover"
                />
            </div>
            <div className={styles.textContainer}>
                {date && (
                    <div className={styles.date}>{formatDateMonth(date)}</div>
                )}
                <div className="line-clamp-2 text-xl font-bold">{title}</div>
                <div
                    className={clsx(
                        styles.subheader +
                            (date ? " line-clamp-2" : " line-clamp-3"),
                    )}
                >
                    {subheader}
                </div>
                <div className={styles.linkText}>{linkDisplayText}</div>
                {children}
            </div>
            {tags && (
                <div className={styles.tags}>
                    {tags.map((tag, i) => (
                        <div className={tagStyle} key={i}>
                            {feTagsFlat[tag]}
                        </div>
                    ))}
                </div>
            )}
        </>
    );
    return (
        <ScaleOnHoverDiv className={styles.parentContainer}>
            {id ? (
                <a href={link} className={styles.linkContainer}>
                    <CardBody />
                </a>
            ) : (
                <Link to={link} className={styles.linkContainer}>
                    <CardBody />
                </Link>
            )}
        </ScaleOnHoverDiv>
    );
}
