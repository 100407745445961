import clsx from "clsx";
import { useState } from "react";
import { Link } from "react-router-dom";

import { date, text, rightColHeader } from "../styles";
import { routesFlat } from "@routes";
import { RocketIcon } from "@assets/icons";

export default function Featured() {
    const [openReadMore, setOpenReadMore] = useState(false);
    return (
        <>
            <div className={rightColHeader}>featured product update</div>
            <Link to={"jmrv-update-2024"}>
                <div className={clsx(date + " tracking-wider mt-2")}>
                    Nov 15, 2024
                </div>
                <div
                    className={clsx(
                        text + (openReadMore ? "" : " line-clamp-3"),
                    )}
                >
                    CTrees added 2023 carbon data to the Jurisdictional MRV tool.
                </div>
            </Link>
            {/* <div
                className={readMore}
                onClick={() => setOpenReadMore(!openReadMore)}
            >
                read {openReadMore ? "less" : "more"}
            </div> */}
            <Link to={routesFlat.jmrv} className="flex text-sm font-bold mt-1">
                <RocketIcon />
                <div className="ml-2 border-b-[1px] border-b-red">Launch App</div>
            </Link>
        </>
    );
}

const readMore = clsx(
    " mb-2 cursor-pointer text-sm text-black/40 underline",
);
