import clsx from "clsx";

export const cardWrapper = clsx(
    "flex w-[380px] h-[260px] flex-col relative rounded-lg bg-blue px-6 py-6 mb-6",
);
export const date = clsx("mb-2 text-sm uppercase text-black/50");
export const summary = clsx("mt-2 line-clamp-2 max-w-[280px] text-sm");
export const tag = clsx(
    "mr-4 rounded-sm bg-white/20 px-4 py-2 text-xxs font-bold uppercase tracking-widest whitespace-nowrap ",
);
