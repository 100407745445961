import Skeleton from "react-loading-skeleton";
import clsx from "clsx";

import { CarouselCards } from "@components";

export default function RelatedArticles({ articles }) {
    const cards = articles.map((item, i) => {
        return {
            date: item.publish_date,
            img: item.image_url,
            imgAlt: item.title,
            title: item.title,
            subheader: item.summary,
            link: item.uri,
            tags: item.product_tags.concat(item.article_tags),
        };
    });

    return (
        <div className={relatedContainer}>
                <div className="mb-10 w-[90%] text-2xl font-bold">
                    Related Articles
                </div>
                {articles.length > 0 ? (
                    <CarouselCards cards={cards} />
                ) : (
                    <div className="flex">
                        {Array(3)
                            .fill()
                            .map((i) => (
                                <Skeleton className="mr-10 h-[400px] !w-[350px]" />
                            ))}
                    </div>
                )}
            </div>
    )
}

const relatedContainer = clsx("md:mb-20 flex w-full flex-col items-center");