import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";

import { routesFlat } from "@routes";
import { BlackLogo } from "@assets/icons";
import { Toggle } from "../";

function getBgColor(currentRoute) {
    if (
        currentRoute.includes(routesFlat.news) ||
        currentRoute.includes(routesFlat.landCarbon)
    ) {
        return " bg-green";
    }

    if (currentRoute.includes(routesFlat.treeLevel)) {
        return " bg-blue";
    }

    return " bg-background/20";
}

export default function Header(props) {
    const currentRoute = useLocation();
    const bgColor = getBgColor(currentRoute.pathname);

    const parentStyle = clsx(parentContainer + bgColor);
    return (
        <div className={parentStyle}>
            <Link
                to={routesFlat.landing}
                className={`flex ${props.isOpen ? "fixed" : ""}`}
            >
                <BlackLogo
                    withText={true}
                    className="max-w-[150px] h-20 ml-4"
                />
            </Link>
            <Toggle {...props} />
        </div>
    );
}

const parentContainer = clsx(
    "absolute top-0 z-40 flex w-full justify-between lg:hidden",
);
