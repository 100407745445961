import clsx from "clsx";

export const parentContainer = clsx(
    "mr-5 md:mx-5 first:ml-8 last:mr-10 focus:outline-none relative mb-10",
);
export const linkContainer = clsx(
    "inline-block h-[500px] md:w-[350px] w-[300px] cursor-pointer overflow-hidden rounded-xl",
);
export const imgContainer = clsx("flex h-3/5 items-center justify-center");
export const textContainer = clsx("h-2/5 w-full relative bg-green p-4");
export const linkText = clsx(
    "text-sm font-bold uppercase absolute bottom-0 mb-6",
);
export const subheader = clsx("my-2 whitespace-normal");
export const date = clsx("text-xs font-bold uppercase tracking-widest");
export const tags = clsx("absolute bottom-0 mb-5 flex pl-4");
