import { BarChartIcon } from "@assets/icons";
import * as styles from "../styles";
import { LinkText } from "..";

export default function LeftCol({ tabContent }) {
    return (
        <div className={styles.colContainer}>
            <div className={styles.featTitle}>Background</div>
            <div className="mr-4">
                {tabContent.background.map((background) => (
                    <div className="mb-3 last:mb-0">
                        {background.title && (
                            <span className="pr-2 italic">
                                {background.title}:
                            </span>
                        )}
                        {background.desc}
                    </div>
                ))}
            </div>
            <div className={styles.featCatTitle}>dataset resolution:</div>
            <div className="italic">{tabContent.resolution}</div>
            <div className={styles.featCatTitle}>temporal frequency:</div>
            <div className="italic">{tabContent.frequency}</div>
            <div className={styles.featTitle}>use case 1</div>
            <div>{tabContent.useCase1.desc}</div>
            <LinkText to={tabContent.useCase1.link}>
                <span className="pr-2">{tabContent.useCase1.icon}</span>
                {tabContent.useCase1.linkText}
            </LinkText>
            {tabContent.useCase2 && (
                <>
                    <div className={styles.featTitle}>use case 2</div>
                    <div>{tabContent.useCase2.desc}</div>
                    <LinkText to={tabContent.useCase2.link}>
                        <span className="pr-2">{tabContent.useCase2.icon}</span>
                        {tabContent.useCase2.linkText}
                    </LinkText>
                </>
            )}
            {tabContent.datasets && (
                <>
                    <div className={styles.featTitle}>additional datasets</div>
                    <div>
                        {tabContent.datasets.map(({ link, linkText }) => (
                            <div className="flex pb-2">
                                <div className="mt-[14px]">
                                    <BarChartIcon />
                                </div>
                                <LinkText to={link} className="pl-2">
                                    {linkText}
                                </LinkText>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
}
