import clsx from "clsx";
import { useState, useEffect } from "react";

import { signUp, validateEmail } from "@helpers";
import { Button } from "@components";
import { text, rightColHeader } from "../styles";

export default function Newsletter() {
    const [email, setEmail] = useState("");
    const [enabled, setEnabled] = useState(false);
    const [displayMsg, setDisplayMsg] = useState();

    const onSetEmail = (e) => {
        const input = e.target.value;
        const valid = validateEmail(input);

        setEmail(input);

        if (valid) {
            setEnabled(true);
        } else {
            setEnabled(false);
        }
    };

    const onClick = (e) => signUp(e, email, setDisplayMsg);

    useEffect(() => {
        if (displayMsg) {
            setTimeout(() => {
                setEmail("");
                setDisplayMsg(null);
            }, 7000);
        }
    }, [displayMsg]);

    const btnProps = {
        primary: false,
        onClick,
        enabled,
        className: "text-xs w-1/3",
    };

    return (
        <>
            <div className={rightColHeader}>subscribe</div>
            <div className={text}>
                Sign up for our monthly newsletter and updates on data, research, and events.
            </div>
            <input
                required
                type="email"
                name="EMAIL"
                id="mce-EMAIL"
                className={inputStyle}
                placeholder="Email"
                value={email}
                onChange={onSetEmail}
            />
            <div className="mb-4 mt-1 text-xs">
                By submitting your email address, you agree to receive
                communications from CTrees. You can opt-out at any time.
            </div>
            <Button {...btnProps}>sign up</Button>
            {displayMsg && <div className={displayMsgStyle}>{displayMsg}</div>}
        </>
    );
}

const inputStyle = clsx(
    "self-start focus:outline-none focus:ring-b focus:ring-blue w-full border-t-0 border-x-0 border-b-[1px]" +
        " placeholder-black/40 text-sm border-b-red contrast-more:placeholder-slate-600 py-1 my-1 text-black" +
        " required email placeholder- border-b-[1px] border-b-red tracking-wider bg-black/0",
);

const displayMsgStyle = clsx(
    "mt-2 rounded-xl p-2 text-xxs w-[fit-content] bg-blue",
);
