import clsx from "clsx";
import { DropdownArrowIcon } from "@assets/icons";

export default function CarouselCounter({
    currentDot,
    numOfDots,
    onClickBack,
    onClickForward,
}) {
    const Dot = ({ current }) => (
        <div
            className={clsx(
                dotStyle + (current ? " bg-green" : " bg-black/20"),
            )}
        />
    );

    return (
        <div className="flex flex-col items-center">
            <div className={dotsAndArrowsContainerStyle}>
                <DropdownArrowIcon
                    onClick={onClickBack}
                    fill={arrowFill}
                    className={clsx(arrowStyle + " mr-10 rotate-90")}
                />
                {numOfDots < 10 ? (
                    Array(numOfDots)
                        .fill("")
                        .map((x, i) => (
                            <Dot key={i} current={i === currentDot} />
                        ))
                ) : (
                    // To mitigate off-screen dot overflow.
                    <>
                        <Dot current={0 === currentDot} />
                        <Dot current={1 === currentDot} />
                        <div className="tracking-widest text-black/40">...</div>
                        <Dot current={numOfDots === currentDot + 1} />
                    </>
                )}
                <DropdownArrowIcon
                    onClick={onClickForward}
                    fill={arrowFill}
                    className={clsx(arrowStyle + " ml-10 rotate-[270deg]")}
                />
            </div>
            <div className={fractionContainerStyle}>
                {currentDot + 1}/{numOfDots}
            </div>
        </div>
    );
}

const arrowFill = "#07080766";

const arrowStyle = clsx("h-2 cursor-pointer");
const dotStyle = clsx("mx-1 h-2 w-2 rounded-full");
const dotsAndArrowsContainerStyle = clsx(
    "flex w-full items-center justify-between",
);
const fractionContainerStyle = clsx(
    "mt-4 flex w-full items-center justify-center text-sm text-black/40",
);
