// Sample Data
export const aboutURI = "https://ctrees-website.s3.us-west-2.amazonaws.com/webv3/about/"

export const teamImages = [
    {
        src: `${aboutURI}SassanSpeakingUCLARetreat.jpg`,
        desc: "Sassan Saatchi, CEO, presents at a CTrees staff retreat in 2024.",
    },
    {
        src: `${aboutURI}LeBien_Gabon.jpg`,
        desc: "Le Bienfaiteur Sagang, research scientist (center), leads a field workshop in Gabon in 2023.",
    },
    {
        src: `${aboutURI}AllStaff_UCLARetreat.jpg`,
        desc: "CTrees team at staff retreat in 2024.",
    },
    {
        src: `${aboutURI}Liz_Retreat.jpeg`,
        desc: "Liz Anderson, full-stack engineer, presents at a CTrees staff retreat in 2024.",
    },
    // {
    //     src: `${aboutURI}SassanPresentingGCF.jpg`,
    //     desc: "CEO Sassan Saatchi (left) demonstrates CTrees at the Governors’ Climate & Forests Task Force Annual Meeting in 2023.",
    // },
    {
        src: `${aboutURI}SassanPresentingGCF.jpg`,
        desc: "Daniel Melling, Director of Marketing (center), presents CTrees data at the Governors' Climate & Forests Task Force annual meeting in 2024.",
    },
    {
        src: `${aboutURI}24_12+Zhihua+AGU24.jpeg`,
        desc: "Scientist Zhihua Liu presents research findings at AGU24 in Washington, D.C.",
    },
    
];