const bucketRoot = "https://ctrees-website.s3.us-west-2.amazonaws.com/webv3";
export const logos = {
    color: `${bucketRoot}/logos/color-logo.svg`,
    black: `${bucketRoot}/logos/black-logo.svg`,
    withText: `${bucketRoot}/logos/logo-with-text.png`,
    blackWithText: `${bucketRoot}/logos/black-logo-text.svg`,
};
export const partners = {
    ageos: `${bucketRoot}/partners/ageos_logo_bw.png`,
    aso: `${bucketRoot}/partners/aso_logo_bw.png`,
    cool: `${bucketRoot}/partners/cooleffect_logo_bw.png`,
    crosswalk: `${bucketRoot}/partners/crosswalk_logo_bw.jpg`,
    taskforce: `${bucketRoot}/partners/gcf_taskforce_logo_bw.png`,
    terra: `${bucketRoot}/partners/terra_global_logo_bw.png`,
    trace: `${bucketRoot}/partners/climatetrace_logo_bw.png`,
    vp: `${bucketRoot}/partners/vibrantplanet_logo_bw.png`,
    cll: `${bucketRoot}/partners/conservationinternational_logo_bw.png`,
    fs: `${bucketRoot}/partners/usforestservice_logo_bw.png`,
};
export const staticTree = `${bucketRoot}/static/static-tree.svg`;

export const beTagsFlat = {
    luca: "luca",
    jmrv: "jmrv",
    activity: "activity",
    carbon: "land_carbon",
    treeLevel: "tree_level",
};

export const feTagsFlat = {
    luca: "LUCA",
    jmrv: "JMRV",
    land_carbon: "Land Carbon",
    tree_level: "Tree Level",
    activity: "Activity Data",
    webinar: "Webinar",
    news: "In The News",
    special_event: "Special Event",
    event: "Event",
    press_release: "Press Release",
    blog: "Blog",
};

export const tagsList = [
    { display: "Blog", be: "blog" },
    { display: "Event", be: "event" },
    { display: "Press Release", be: "press_release" },
    { display: "In The News", be: "news" },
    { display: "Webinar", be: "webinar" },
    { display: "Land Carbon", be: beTagsFlat.carbon },
    { display: "Activity Data", be: beTagsFlat.activity },
    { display: "JMRV", be: beTagsFlat.jmrv },
    { display: "LUCA", be: beTagsFlat.luca },
    { display: "Tree Level", be: beTagsFlat.treeLevel },
];

export const newsFilterOptions = {
    product: {
        luca: "LUCA",
        jmrv: "JMRV",
        land_carbon: "Land Carbon",
        tree_level: "Tree Level",
        activity: "Activity Data",
        none: "All",
    },
    type: {
        webinar: "Webinar",
        news: "In The News",
        event: "Event",
        press_release: "Press Release",
        blog: "Blog",
        none: "All",
    },
};
