import { useContext } from "react";

import Lottie from "react-lottie";
import * as lottie from "@assets/lottie";

import { NewsContext } from "@";
import * as components from "@components";
import { MostRecent, AllInsights } from "./components";
import clsx from "clsx";

const metaProps = {
    title: "News and updates on global forest carbon from CTrees",
    desc: "CTrees’ data products, science, and partnerships to protect and restore forests are constantly evolving. Learn more about our work.",
    img: "",
};

export default function News() {
    const { articles } = useContext(NewsContext);

    const featured = articles?.slice(0, 5);
    const cards = featured.map((item, i) => {
        return {
            date: item.publish_date,
            img: item.image_url,
            imgAlt: item.title,
            title: item.title,
            subheader: item.summary,
            link: item.uri,
            tags: item.product_tags.concat(item.article_tags),
        };
    });

    const lottieOptions = {
        ...lottie.defaultOptions,
        animationData: lottie.Line,
    };

    return (
        <>
            <components.MetaTagHeader {...metaProps} />
            <div className="flex w-full justify-center">
                <div className={containerStyle}>
                    {/* Featured Article */}
                    <MostRecent article={featured[0]} />
                    <div className="mb-10 md:hidden">
                        <Lottie options={lottieOptions} height={200} />
                    </div>

                    {/* Featured Articles */}
                    <div className="mb-10 w-full text-2xl font-bold">
                        Featured Articles
                    </div>

                    <components.CarouselCards cards={cards} />
                    <components.DottedDivider className="mb-10 w-full lg:hidden" />
                    <AllInsights articles={articles} />
                </div>
            </div>
            <div className="mb-10 md:hidden">
                <Lottie options={lottieOptions} height={200} />
            </div>

            <components.StayConnected />
        </>
    );
}

const containerStyle = clsx(
    "mb-10 mt-16 flex w-[90%] flex-col items-center lg:mb-40 lg:mt-24",
);
