import { useState, useEffect } from "react";
import { getRelatedArticles } from "@helpers";

import * as components from "@components";
import clsx from "clsx";

export default function News({ props }) {
    const [productArticles, setProductArticles] = useState([]);
    const [cards, setCards] = useState([]);

    useEffect(() => {
        getRelatedArticles(props).then((articles) => {
            setProductArticles(articles);
        });
    }, []);

    useEffect(() => {
        const newCards = productArticles.map((article) => ({
            title: article.title,
            subheader: article.summary,
            img: article.image_url,
            imgAlt: "",
            link: `/news/${article.uri}`,
            linkDisplayText: "Read Articles",
        }));

        setCards(newCards);
    }, [productArticles]);

    return (
        <div className={parentContainer}>
            <components.UnderlinedSectionHeader className="mb-10">
                News
            </components.UnderlinedSectionHeader>
            {cards.length > 0 ? (
                <components.CarouselCards cards={cards} />
            ) : (
                <components.NoCard product={props} />
            )}
        </div>
    );
}

const parentContainer = clsx(
    "mt-20 flex w-full flex-col items-center md:mt-40",
);
