import { useState } from "react";
import clsx from "clsx";
import { Hero, Tabs, Feature } from "./components";
import { tabs, tabsContent } from "./constants";

export default function DataOverview() {
    const [selectedTab, setSelectedTab] = useState(0);
    const featureProps = { selectedTab, tabs, tabsContent: tabsContent() };
    const tabProps = { ...featureProps, setSelectedTab };
    return (
        <>
            <Hero />
            <div className={parentContainer}>
                <div className="flex max-w-[90%]">
                    <Tabs {...tabProps} />
                    <Feature {...featureProps} />
                </div>
            </div>
        </>
    );
}

const parentContainer = clsx("my-20 flex w-full items-center justify-center");
