import Skeleton from "react-loading-skeleton";
import clsx from "clsx";

export default function ArticleSkeleton() {
    return (
        <div className={skeletonWrapper}>
            <Skeleton className="h-[50px] !w-[600px]" />
            <Skeleton className="h-[20px] !w-[300px]" />
            <Skeleton className="mb-10 h-[20px] !w-[100px]" />
            {Array(30)
                .fill()
                .map((i) => (
                    <Skeleton className="h-[20px] !w-[600px] last:mb-10" />
                ))}
        </div>
    );
}

const skeletonWrapper = clsx("mt-32 flex w-full flex-col items-center");