import * as React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Login, AuthCallback } from "./components";
import * as pages from "./pages";

export const routesFlat = {
    //--- internal links ---//
    landing: "/",
    contact: "/contact",
    notFound: "*",
    // legal
    terms: "/terms-of-use",
    privacy: "/privacy-policy",
    // solutions
    carbonMarkets: "/carbon-markets",
    countryState: "/country-and-state",
    globalAssessment: "/global-assessment",
    // data
    products: "/products",
    luca: "/luca",
    jmrv: "/jmrv",
    reddai: "/reddai",
    treeLevel: "/tree-level",
    landCarbon: "/land-carbon",
    activityData: "/activity-data",
    // about
    about: "/about",
    // news
    news: "/news",
    // cms
    admin: "/admin",
    // login
    login: "/login",
    callback: "/callback",
    //--- external links ---//
    youtube: "https://www.youtube.com/channel/UCUUkj-U_u53Nu9zbFR4RUeg",
    linkedin: "http://linkedin.com/company/ctrees",
    treeLevelAWSRegistry:
        "https://registry.opendata.aws/ctrees-california-vhr-tree-height/",
    //--- retired links - DO NOT USE ---//
    retiredLuca: "/products/luca",
    retiredLucaMap: "/products/luca/map",
    retiredJmrv: "/products/jmrv",
    retiredJmrvMap: "/products/jmrv/map",
    retiredActivity: "/products/reddai",
    retiredTreeLevel: "/products/tree-level",
    retiredCarbon: "/products/land-carbon",
};

const loggedIn = localStorage.getItem("token");

export const routes = [
    {
        display: "Home",
        path: routesFlat.landing,
        element: <pages.Landing />,
        displayInNav: false,
    },
    {
        display: "Solutions",
        displayInNav: true,
        nested: [
            {
                display: "Carbon Markets",
                path: routesFlat.carbonMarkets,
                element: <pages.CarbonMarkets />,
            },
            {
                display: "Country & State Programs",
                path: routesFlat.countryState,
                element: <pages.CountryAndState />,
            },
            {
                display: "Global Assessment",
                path: routesFlat.globalAssessment,
                element: <pages.GlobalAssessment />,
            },
        ],
    },
    {
        display: "Data",
        displayInNav: true,
        nested: [
            {
                display: "Overview",
                path: routesFlat.products,
                element: <pages.DataOverview />,
                divide: true,
            },
            {
                display: "Land Carbon Map",
                path: routesFlat.landCarbon,
                element: <pages.LandCarbon />,
            },
            {
                display: "Tree Level Data",
                path: routesFlat.treeLevel,
                element: <pages.TreeLevel />,
            },
            {
                display: "Activity Data",
                path: routesFlat.activityData,
                element: <pages.ActivityData />,
                divide: true,
            },
            {
                display: "Jurisdictional MRV (JMRV)",
                path: routesFlat.jmrv,
                element: <pages.JMRVMap />,
            },
            {
                display: "Land Use Change Alerts (LUCA)",
                path: routesFlat.luca,
                element: <pages.LucaMap />,
            },
            {
                display: "REDD+AI",
                path: routesFlat.reddai,
                element: <pages.ReddaiMap />,
            },
        ],
    },
    {
        display: "About",
        path: routesFlat.about,
        element: <pages.About />,
        displayInNav: true,
    },
    {
        display: "News",
        path: routesFlat.news,
        element: <pages.News />,
        displayInNav: true,
    },
    {
        display: "News",
        path: routesFlat.news + "/:id",
        element: <pages.Article />,
        displayInNav: false,
    },
    {
        display: "Contact",
        path: routesFlat.contact,
        element: <pages.Contact />,
        displayInNav: true,
    },
    {
        display: "Admin",
        path: routesFlat.admin,
        element: (
            <Login>
                <pages.CMS />
            </Login>
        ),
        displayInNav: false,
    },
    {
        display: "Terms of Use",
        path: routesFlat.terms,
        element: <pages.Terms />,
        displayInNav: false,
    },
    {
        display: "Privacy Policy",
        path: routesFlat.privacy,
        element: <pages.Privacy />,
        displayInNav: false,
    },
    {
        display: "404",
        path: "*",
        element: <pages.NotFound />,
        displayInNav: false,
    },
    {
        display: "Callback",
        path: routesFlat.callback,
        element: <AuthCallback />,
        displayInNav: false,
        requireAuth: false,
    },
    //--- RETIRED ROUTES: REDIRECTS ---//
    {
        path: routesFlat.retiredActivity,
        element: <Navigate to={routesFlat.reddai} />,
        displayInNav: false,
    },
    {
        path: routesFlat.retiredCarbon,
        element: <Navigate to={routesFlat.landCarbon} />,
        displayInNav: false,
    },
    {
        path: routesFlat.retiredTreeLevel,
        element: <Navigate to={routesFlat.treeLevel} />,
        displayInNav: false,
    },
    {
        path: routesFlat.retiredLuca,
        element: <Navigate to={routesFlat.luca} />,
        displayInNav: false,
    },
    {
        path: routesFlat.retiredJmrv,
        element: <Navigate to={routesFlat.jmrv} />,
        displayInNav: false,
    },
    {
        path: routesFlat.retiredJmrvMap,
        element: <Navigate to={routesFlat.jmrv} />,
        displayInNav: false,
    },
    {
        path: routesFlat.retiredLucaMap,
        element: <Navigate to={routesFlat.luca} />,
        displayInNav: false,
    },
];

export const navRoutes = routes.filter((route) => route.displayInNav);

const RoutesMap = (routes) => {
    return routes.map(({ path, element, nested = null }, i) => {
        if (nested || path === routesFlat.landing) {
            return (
                <Route key={path + i} path={path}>
                    <Route index={true} element={element} />
                    {nested && RoutesMap(nested)}
                </Route>
            );
        }

        return <Route path={path} key={path} element={element} />;
    });
};
export default function RoutesComp() {
    return <Routes>{RoutesMap(routes)}</Routes>;
}
