import { useState, useEffect } from "react";
import { paginate } from "@helpers";
import { CarouselCounter, BlueCard } from "@components";
import clsx from "clsx";

export default function AllInsights({ articles }) {
    const [currentPaginatedPage, setPaginatedPage] = useState(0);
    const [articlesPerPage, setArticlesPerPage] = useState(9);

    const moveBackAPage = () => {
        if (currentPaginatedPage > 0) {
            setPaginatedPage(currentPaginatedPage - 1);
        }
    };
    const moveForwardAPage = () => {
        if (currentPaginatedPage + 1 < paginatedArticles.length) {
            setPaginatedPage(currentPaginatedPage + 1);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 767){ // sm screens
                setArticlesPerPage(2); 
            }
            else if (window.innerWidth >= 1024) { // lg screens
                setArticlesPerPage(9); 
            } else { // md screens
                setArticlesPerPage(4); 
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => {
            console.log('return')
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const paginatedArticles = paginate(articles, articlesPerPage);

    const [articlesInView, setArticlesInView] = useState(paginatedArticles[0]);

    useEffect(() => {
        setArticlesInView(paginatedArticles[currentPaginatedPage]);
    }, [currentPaginatedPage, articles]);

    return (
        <>
            {/* Blue Cards Header */}
            <div className="flex w-full justify-between">
                <div className="mb-10 text-2xl font-bold">All Insights</div>
                {/* NOTE: v2 Release */}
                {/* <div className="flex w-[300px] justify-around text-xl tracking-wider">
                            <div className="uppercase">Search</div>
                            <div className="uppercase">Filter</div>
                        </div> */}
            </div>

            {/* Blue Cards */}
            <div className={blueCardContainer}>
                {articlesInView?.map((item, i) => (
                    <BlueCard className={"w-full"} article={item} />
                ))}
            </div>
            <CarouselCounter
                numOfDots={paginatedArticles.length}
                currentDot={currentPaginatedPage}
                onClickBack={moveBackAPage}
                onClickForward={moveForwardAPage}
            />
        </>
    );
}

const blueCardContainer = clsx(
    "grid w-full grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 flex-wrap content-center items-center justify-center",
);
