import { useRef, useState, useEffect } from "react";
import clsx from "clsx";

const handleOnSlider = () => {
  return (
      <div className="shadow-inner-lg rounded-full bg-offWhite p-0.5">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              height="14px"
              width="14px"
              viewBox="0 -960 960 960"
              fill="black"
          >
              <path d="M480-120 300-300l58-58 122 122 122-122 58 58-180 180ZM358-598l-58-58 180-180 180 180-58 58-122-122-122 122Z" />
          </svg>
      </div>
  );
};

export default function ImageComparisonSlider({ imgBefore, imgAfter, imgBeforeAlt, imgAfterAlt }) {
    const containerRef = useRef(null);
    const [sliderPosition, setSliderPosition] = useState(50);
    const [isDragging, setIsDragging] = useState(false);

    useEffect(() => {
        const handleMove = (e) => {
            if (!isDragging || !containerRef.current) return;

            const containerRect = containerRef.current.getBoundingClientRect();
            const newX =
                e.touches?.[0]?.clientX - containerRect.left ||
                e.clientX - containerRect.left;

            const newPercentage = (newX / containerRect.width) * 100;
            setSliderPosition(Math.max(0, Math.min(100, newPercentage)));
        };

        const stopDrag = () => setIsDragging(false);

        // vanilla javascript works smoother for click and drag events
        if (isDragging) {
            window.addEventListener("mousemove", handleMove);
            window.addEventListener("mouseup", stopDrag);
            window.addEventListener("touchmove", handleMove);
            window.addEventListener("touchend", stopDrag);
        }

        return () => {
            window.removeEventListener("mousemove", handleMove);
            window.removeEventListener("mouseup", stopDrag);
            window.removeEventListener("touchmove", handleMove);
            window.removeEventListener("touchend", stopDrag);
        };
    }, [isDragging]);

    return (
        <div ref={containerRef} className={containerStyle}>
            {/* Before Image (Fixed) */}
            <img src={imgBefore} alt={imgBeforeAlt} className={imgStyle} />

            {/* After Image (Slides in) */}
            <img
                src={imgAfter}
                alt={imgAfterAlt}
                className={imgStyle}
                style={{ clipPath: `inset(0 ${100 - sliderPosition}% 0 0)` }}
            />

            {/* Slider Handle */}
            <div
                className={sliderHandleStyle}
                style={{
                    left: `${sliderPosition}%`,
                }}
                onMouseDown={() => setIsDragging(true)}
                onTouchStart={() => setIsDragging(true)}
            >
                <div className={handleOnSliderStyle}>
                    {handleOnSlider()}
                </div>
            </div>
        </div>
    );
}

const containerStyle = clsx(
    "relative h-[300px] w-[360px] select-none overflow-hidden rounded-lg shadow-lg",
);
const imgStyle = clsx("absolute inset-0 h-full w-full object-cover");
const sliderHandleStyle = clsx(
    "absolute inset-y-0 w-0.5 cursor-ew-resize bg-offWhite/80 shadow-xl transform -translate-x-1/2 shadow-sliderHandle",
);
const handleOnSliderStyle = clsx("absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rotate-90");
