import clsx from "clsx";
import { useState } from "react";
import { UnderlinedSectionHeader, CarouselCards } from "@components";

export default function Data({ cards, subheader }) {
    return (
        <div className={parentContainerStyle}>
            <UnderlinedSectionHeader>Data</UnderlinedSectionHeader>
            <div className={subheaderStyle}>{subheader}</div>
            <div className="w-full">
                <CarouselCards cards={cards} />
            </div>
        </div>
    );
}

const parentContainerStyle = clsx(
    "w-full flex flex-col items-center mt-20 md:mt-40",
);
const subheaderStyle = clsx(
    "my-10 max-w-[550px] text-center text-lg md:text-2xl mx-8",
);

Data.prototypes = {
    cards: Array,
    subheader: String,
};
